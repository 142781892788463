import React from "react"
import styled from "styled-components"

const English = styled.h3`
  margin: 0;
  display: ${props => !props.show && "none"};
`

export default (props) => (
  <English show={props.show}>
    {props.english}
  </English>
)
