import React from "react"
import QuizMaster from "../../../components/layouts/quiz_master"
import English from "../../../components/quiz/english"

const Furigana = require("gem-furigana").Furigana
const verbs = require("../../../components/japanese/data/transitive.json")

class Transitive extends React.Component  {
  constructor(props) {
    super(props);
    this.verb = {};
    this.lastVerb = {};
    this.answer = "";
    this.qmeaning = "";
    this.correct = 0;
    this.incorrect = 0;
    this.reverse = false;
    this.newVerb = this.newVerb.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);

    this.state = {
        loaded: false,
        verb: this.verb,
        question: "",
        lastAnswer: "",
        qmeaning: "",
        status: "",
        wait: false,
        correct: this.correct,
        incorrect: this.incorrect,
    };
  }

  newVerb() {
    this.verb = QuizMaster.generateItem(verbs, this.lastVerb);
    this.reverse = Math.floor(Math.random() * 2) === 0;
    this.lastVerb = this.verb;

    this.setState({ verb: this.verb, lastVerb: this.verb, wait: false, status: "" });
    this.updateQuestion()
    this.updateAnswer()
  }

  updateQuestion() {
    var verb = this.reverse ? this.verb.i : this.verb.t;
    this.question = verb.verb;
    this.qmeaning = verb.meaning;
    this.setState({ question: this.question, qmeaning: this.qmeaning });
  }

  updateAnswer() {
    this.answer = this.reverse ? this.verb.t.verb : this.verb.i.verb;
  }

  checkAnswer(userAnswer) {
    var status = "";
    var answer = new Furigana(this.answer);
    if(answer.Expression === userAnswer || answer.Hiragana === userAnswer) {
      status = "correct";
      this.correct += 1;
    } else {
      status = "incorrect";
      this.incorrect += 1;
    }
    this.setState({status: status, correct: this.correct, incorrect: this.incorrect, lastAnswer: answer.Hiragana, wait: true})
  }

  componentDidMount() {
    if(!this.state.loaded) {
      this.newVerb()
      this.setState({ loaded: true });
    }
  }

  render() {
    return (
      <QuizMaster
        title="Japanese Transitive / Intransitive Verb Pairs"
        description="Interactive Study tool for learning Japanese transitive and intransitive pairs."
        furi={true}
        ruby={true}
        question={`${this.reverse ? "Intransitive" : "Transitive"} form of ${new Furigana(this.state.question).ReadingHtml}`}
        postQuestion={<><English english={this.state.qmeaning} show={true} /><br></br></>}
        wait={this.state.wait}
        status={this.state.status}
        onSubmit={this.checkAnswer}
        onClear={this.newVerb}
        correct={this.state.correct}
        incorrect={this.state.incorrect}
        answer={this.state.lastAnswer}
        footer={
          <>
            <br></br><br></br>
            <div><span role="img" aria-label="wip">🚧</span> <em>This page is a work in progress. Expect improvements soon. Verb pairs and definitions are taken from <a href="http://nihongo.monash.edu/ti_list.html">this list</a>.</em> <span role="img" aria-label="wip">🚧</span></div>
          </>
        }
      >        
    </QuizMaster>
    );
  }
}

export default Transitive
